<template>
  <b-overlay :show="loading">
    <b-card>
      <b-row>
        <b-col
          class="text-right"
          cols="4"
        >Template:</b-col>
        <b-col cols="6">
          <b-form-select
            v-model="selectedId"
            :disabled="loading"
            @change="selectTemplate"
          >
            <b-form-select-option :value="null">Select a Template</b-form-select-option>
            <b-form-select-option
              v-for="t in templates"
              :key="t.id"
              :value="+t.id"
            >
              {{ t.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <div v-if="selected">
        <b-card
          no-body
          bg-variant="light"
          class="my-3 text--black"
        >
          <Editor
            v-model="selected.content"
            api-key="83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu"
            :init="tinymceConfig"
          ></Editor>
        </b-card>

        <b-row>
          <b-col v-if="selected.previous">
            <b-button
              variant="danger"
              @click.prevent="revert"
              :disabled="loading"
            >
              Revert Last Changes
            </b-button>
          </b-col>
          <b-col v-if="selected.type === 'contract'">
            <b-button
              variant="info"
              @click.prevent="preview"
              :disabled="loading"
            >
              Preview PDF
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="success"
              @click.prevent="save"
              :disabled="loading"
            >Save Changes</b-button>
          </b-col>
          <b-col>
            <b-button
              variant="secondary"
              @click.prevent="cancel"
              :disabled="loading"
            >Cancel</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="showRevert"
      size="md"
      title="Revert Last Changes"
      button-size="sm"
      ok-variant="danger"
      ok-title="Revert"
      no-close-on-backdrop
      no-close-on-esc
      v-if="selected && selected.previous"
      @ok="revert"
    >
      <p class="text-center">Are you sure you want to revert the last changes made to this template?</p>
    </b-modal>
  </b-overlay>
</template>
<style scoped>
.contract-container {
  color: black;
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  margin: 1em auto;
  padding: 0 1em;
  text-align: left;
}
.contract-container a {
  color: black !important;
}
</style>
<script>
import TemplateService from '@/services/TemplateService'
import ContractService from '@/services/ContractService'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Templates',
  components: { Editor },

  data () {
    return {
      loading: false,
      showRevert: false,
      templates: [],
      selectedId: null,
      selected: null,
      tinymceConfig: {
        height: 650,
        menubar: false,
        plugins: [
          'advlist autolink lists image charmap preview anchor',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | bold italic underline backcolor | bullist numlist outdent indent | removeformat | help',
        inline_styles: false,
        formats: {
          underline: { inline: 'u', exact: true }
        }
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      TemplateService.init().then((response) => {
        this.templates = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    load (id) {
      this.loading = true
      TemplateService.get(id).then((response) => {
        this.selected = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    cancel () {
      this.selectedId = null
      this.selected = null
    },

    save () {
      if (this.loading) {
        return false
      }
      this.loading = true
      TemplateService.save(this.selected).then(() => {
        this.selected = null
        this.$aimNotify.notify(null, 'info', 'Template Saved', 'This template was saved successfully.')
        this.$nextTick(() => {
          this.load(this.selectedId)
        })
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    selectTemplate (id) {
      if (this.loading) {
        return false
      }
      this.selectedId = id
      this.selected = null
      if (this.selectedId) {
        this.loading = true
        TemplateService.get(id).then((response) => {
          this.selected = response.data.info
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        }).finally(() => {
          this.loading = false
        })
      }
    },

    // generate the contract PDF for preview with the current template changes loaded
    preview () {
      if (this.loading) {
        return false
      }

      ContractService.preview(this.selected).then((response) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href so we have to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers: create a link pointing to the ObjectURL containing the blob
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = 'SBS-Contract-Preview.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    },

    revert () {
      if (this.loading) {
        return false
      }
      this.loading = true
      TemplateService.revert(this.selected.id).then(() => {
        this.selected = null
        this.$aimNotify.notify(null, 'info', 'Changes Reverted', 'This template has been reverted to the previous version.')
        this.$nextTick(() => {
          this.load(this.selectedId)
        })
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
