import axios from 'axios'

const TicketService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'template/init',
      method: 'GET'
    })
  },

  async get (id) {
    return axios({
      url: axios.defaults.baseURL + 'template/get/' + id,
      method: 'GET'
    })
  },

  async save (data) {
    return axios({
      url: axios.defaults.baseURL + 'template/save',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async revert (id) {
    return axios({
      url: axios.defaults.baseURL + 'template/revert',
      method: 'POST',
      data: JSON.stringify({ id: +id })
    })
  }
}

export default TicketService
